import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { setActiveUser, setUserType, setUserLogOutState } from './store/slices/userReducer';
import app from './auth/firebase';
import { Layout, Spin } from 'antd';
import DashboardPage from './pages/Dashboard';
import SearchPage from './pages/Search';
import ProjectDetail from './pages/ProjectDetail';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import UsersManagement from './pages/Admin/UsersManagement';
import Sidebar from './components/Sidebar/Sidebar';
import AlertBanner from './components/AlertBanner';
import { connectWebSocket, closeWebSocket } from './ws/websocket';

const { Sider, Content } = Layout;


const PrivateRoute = ({ element: Element, ...rest }) => {
  const userEmail = useSelector((state) => state.user.userEmail);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userEmail) {
      connectWebSocket(dispatch, setUserType, userEmail).catch(error => {
        console.error('Failed to connect WebSocket:', error);
      });
    }
  }, [userEmail]);  

  return userEmail ? <Element {...rest} /> : <Navigate to="/login" />;
};

const App = () => {
  const dispatch = useDispatch();
  const auth = getAuth(app);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user.userType);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (userAuth) => {
      if (userAuth) {
        dispatch(setActiveUser({
          userName: userAuth.displayName,
          userEmail: userAuth.email,
          userID: userAuth.uid,
        }));
      } else {
        dispatch(setUserLogOutState());
        closeWebSocket();
      }
      setLoading(false);
    });

    return () => {
      unsubscribe();
      closeWebSocket();
    };
  }, [auth, dispatch]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Router>
      <Layout style={{ minHeight: '100vh' }}>
        {user && <Sider collapsible defaultCollapsed={true}><Sidebar /></Sider>}
        <Layout>
          <Content style={{ overflow: 'initial' }}>
            <AlertBanner />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<PrivateRoute element={DashboardPage} />} />
              <Route path="/admin/users" element={<PrivateRoute element={UsersManagement} />} />
              <Route path="/search" element={<PrivateRoute element={SearchPage} />} />
              <Route path="/projects/:id" element={<PrivateRoute element={ProjectDetail} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Router>
  );
};

export default App;
