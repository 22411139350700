let socket;
function getWebSocketUrl() {
  let protocol = 'wss'; // Default to secure WebSocket (wss)
  let port = window.location.port; // Use the same port as the current host

  if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    protocol = 'ws'; // Use non-secure WebSocket (ws) for local development
  }

  if (window.location.port === '3000') {
    port = '8083'; // Replace with your WebSocket server port if different
  }

  return `${protocol}://${window.location.hostname}:${port}/ws`;
}

export function connectWebSocket(dispatch, setUserType, userEmail) {
  return new Promise((resolve, reject) => {
    const wsUrl = getWebSocketUrl();
    const socket = new WebSocket(wsUrl); // Ensure socket is properly declared

    socket.onopen = async () => {
      try {
        await sendMessage(socket, { code: 'GET_USER_TYPE', email: userEmail });
      } catch (error) {
        console.error('Error sending message:', error);
      }
      resolve(); // Resolve the promise when the WebSocket is connected
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.code === 'USER_TYPE_RESPONSE') {
        dispatch(setUserType(data.userType));
      }
    };

    socket.onclose = () => {
      reject(new Error('WebSocket disconnected unexpectedly'));
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
      reject(error);
    };
  });
}



export function sendMessage(socket, message) {
  return new Promise((resolve, reject) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const messageId = Date.now(); // Unique ID for this message
      socket.send(JSON.stringify({ ...message, id: messageId })); // Include the ID in the message
      resolve();
    } else {
      console.error('WebSocket is not open. Ready state is:', socket ? socket.readyState : 'no socket');
      reject(new Error('WebSocket is not open'));
    }
  });
}


export function closeWebSocket() {
  if (socket) {
    socket.close();
    socket = null; // Set to null after closing
  }
}
