import React, { useState, useEffect } from 'react';
import { Input, List, Button, Card, Row, Col, Spin, message } from 'antd';
import axios from 'axios';
import { BASE_API_URL } from '../util/constants';

const { Search } = Input;

const SearchPage = () => {
  const [loading, setLoading] = useState(false);
  const [plugins, setPlugins] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedPlugin, setSelectedPlugin] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);

  // Fetch all plugins when the component mounts and sort them alphabetically
  useEffect(() => {
    const fetchAllPlugins = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${BASE_API_URL}/api/plugins`);
        const sortedPlugins = response.data.sort((a, b) =>
          a.plugin_name.localeCompare(b.plugin_name)
        );
        setPlugins(sortedPlugins);
      } catch (error) {
        message.error('Error fetching plugins');
      } finally {
        setLoading(false);
      }
    };

    fetchAllPlugins();
  }, []);

  // Function to search projects by plugin name
  const searchProjectsByPlugin = async (pluginName) => {
    if (!pluginName) {
      message.warning('Please select a plugin to search');
      return;
    }

    setLoadingSearch(true);
    try {
      const response = await axios.get(`${BASE_API_URL}/api/search/${pluginName}`);
      setSearchResults(response.data);
      // Scroll to top when search is initiated
      window.scrollTo(0, 0);
    } catch (error) {
      message.error('Error searching for projects');
    } finally {
      setLoadingSearch(false);
    }
  };

  return (
    <div style={{ padding: '24px' }}>
      <Row gutter={16}>
        {/* Column for listing all plugins */}
        <Col span={12}>
          <Card title="All Plugins" bordered={false}>
            {loading ? (
              <Spin size="large" />
            ) : (
              <List
                itemLayout="horizontal"
                dataSource={plugins}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button
                        type="link"
                        onClick={() => {
                          setSelectedPlugin(item.plugin_name);
                          searchProjectsByPlugin(item.plugin_name);
                        }}
                      >
                        Search Sites
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta
                      title={item.plugin_name}
                    />
                  </List.Item>
                )}
              />
            )}
          </Card>
        </Col>

        {/* Column for searching and displaying results */}
        <Col span={12}>
          <Card title="Search Sites by Plugin" bordered={false}>
            <Search
              placeholder="Search plugin"
              value={selectedPlugin}
              onChange={(e) => setSelectedPlugin(e.target.value)}
              onSearch={() => searchProjectsByPlugin(selectedPlugin)}
              enterButton="Search"
              loading={loadingSearch}
            />
            <div style={{ marginTop: '16px' }}>
              {loadingSearch ? (
                <Spin size="large" />
              ) : (
                <List
                  itemLayout="horizontal"
                  dataSource={searchResults}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        title={<a href={`/projects/${item.project_id}`}>{item.project_title}</a>}
                        description={(
                          <>
                            Project ID: <a href={`/projects/${item.project_id}`}>{item.project_id}</a>
                            <br />
                            Version Installed: {item.plugin_version}
                          </>
                        )}
                      />
                    </List.Item>
                  )}
                />
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SearchPage;
