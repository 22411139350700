import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import app from "../auth/firebase";
import axios from "axios";
import { setActiveUser } from "../store/slices/userReducer.js";
import { GoogleOutlined } from '@ant-design/icons';
import { Button, Divider, notification } from 'antd';
import Logo from '../images/logo.js';
import { BASE_API_URL } from '../util/constants';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = getAuth(app);
  const googleProvider = new GoogleAuthProvider();
  const userType = useSelector((state) => state.user.userType);
  googleProvider.setCustomParameters({
    hd: 'maddenmedia.com'
  });
  const [api, contextHolder] = notification.useNotification();

  const openNotification = () => {
    api.error({
      message: 'Access Denied',
      description: 'Not permitted. Please contact support.',
    });
  };

  const handleGoogleSignIn = () => {
    const API_KEY = 'mysecretkey';  // Use environment variable in production

    signInWithPopup(auth, googleProvider)
      .then(async (result) => {
        const { uid, displayName, email } = result.user;

        // Call backend to store/update user info
        try {
          await axios.post(`${BASE_API_URL}/api/users`, {
            firebaseId: uid,
            email: email,
            username: displayName
          }, {
            headers: {
              'x-api-key': API_KEY
            }
          });
          // Update Redux store with all user info including user_type from the API response
          dispatch(setActiveUser({
            userName: displayName,
            userEmail: email,
            userID: uid
          }));
          
          navigate('/');
        } catch (error) {
          console.error('Error saving user to database:', error);
          notification.error({
            message: 'Database Error',
            description: 'Could not save user data. Please try again.'
          });
        }
      })
      .catch((error) => {
        console.log(error.message);
        openNotification();
      });
  };

  return (
    <div className="login-container">
      {contextHolder}
      <div className="login-box">
        <Logo />
        <Divider />
        <Button onClick={handleGoogleSignIn} icon={<GoogleOutlined />}>Sign in with Google</Button>
      </div>
    </div>
  );
}

export default Login;
