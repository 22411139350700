import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Descriptions, Row, Col, Card, Divider, Table, Switch, Input, Form, Button, Select } from 'antd';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { useParams } from 'react-router-dom';
import { ProjectSettings } from '../components/ProjectSettings';
import { BASE_API_URL } from '../util/constants';

const { Option } = Select;

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const ProjectDetail = () => {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [metricsCharts, setMetricsCharts] = useState({});
  const [metricsSettings, setMetricsSettings] = useState({});
  const [plugins, setPlugins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState(false);

  // make chart.js responsive and set the y scale to a full max of 100
  const chartOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true, // also min: 0 would work
        max: 100
      }
    }
  };

  useEffect(() => {
    fetchProject();
    fetchMetrics();
    fetchPlugins();
  }, [id]);

  const fetchProject = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/project/${id}`);
      setProject(response.data);
    } catch (error) {
      console.error('Error fetching project:', error);
      setApiError(true);
    } finally {
      setLoading(false);
    }
  };

  const fetchMetrics = async (hours) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/project/${id}/metrics`, {
        params: { hours }
      });
      organizeMetrics(response.data.metricsHistory);
    } catch (error) {
      console.error('Error fetching metrics:', error);
    }
  };

  const fetchPlugins = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/project/${id}/plugins`);
      setPlugins(response.data);
    } catch (error) {
      console.error('Error fetching plugins:', error);
    }
  };

  const organizeMetrics = (data) => {
    const metricsMap = data.reduce((acc, metric) => {
      const { metric_name, metric_value, recorded_at } = metric;
      if (!acc[metric_name]) {
        acc[metric_name] = [];
      }
      acc[metric_name].push({ value: parseFloat(metric_value), recorded_at });
      return acc;
    }, {});

    setMetricsCharts(Object.keys(metricsMap).map(metricName => ({
      name: metricName,
      data: formatLineChartData(metricName, metricsMap[metricName])
    })));
  };

  const formatLineChartData = (metricName, data) => ({
    labels: data.map(metric => new Date(metric.recorded_at).toLocaleTimeString()),
    datasets: [
      {
        label: metricName,
        data: data.map(metric => metric.value),
        fill: false,
        borderColor: 'rgba(75,192,192,1)',
        tension: 0.1
      }
    ]
  });

  const handleAlertChange = (metricName, value) => {
    setMetricsSettings({
      ...metricsSettings,
      [metricName]: { ...metricsSettings[metricName], alert: value }
    });
  };

  const handleThresholdChange = (metricName, value) => {
    setMetricsSettings({
      ...metricsSettings,
      [metricName]: { ...metricsSettings[metricName], threshold: value }
    });
  };

  const handleTimeframeChange = (metricName, value) => {
    setMetricsSettings({
      ...metricsSettings,
      [metricName]: { ...metricsSettings[metricName], timeframe: value }
    });
    fetchMetrics(value);
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="project-wrapper">
      {project ? <ProjectSettings project={project} /> : <p>Loading...</p>}
      <Divider />
      <Row gutter={16}>
        {metricsCharts.length > 0 && metricsCharts.map((chart, index) => (
          <Col key={index} span={12}>
            <Card title={chart.name} bordered>
              <Line data={chart.data} options={chartOptions} />
              <Form layout="inline" style={{ marginTop: 20 }}>
                <Form.Item label="Send Alert">
                  <Switch
                    checked={metricsSettings[chart.name]?.alert}
                    onChange={(value) => handleAlertChange(chart.name, value)}
                  />
                </Form.Item>
                <Form.Item label="Threshold Value">
                  <Input
                    value={metricsSettings[chart.name]?.threshold}
                    onChange={(e) => handleThresholdChange(chart.name, e.target.value)}
                  />
                </Form.Item>
                <Form.Item label="Timeframe (hours)">
                  <Select
                    value={metricsSettings[chart.name]?.timeframe}
                    onChange={(value) => handleTimeframeChange(chart.name, value)}
                    style={{ width: 120 }}
                  >
                    <Option value="24">24 hours</Option>
                    <Option value="72">72 hours</Option>
                    <Option value="168">168 hours</Option>
                    <Option value="730">730 hours</Option>
                  </Select>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        ))}
      </Row>
      <Divider />
      {apiError ? (
        <p>Error: Unable to fetch project details. Please check your connection or contact support.</p>
      ) : (
        <Row gutter={16}>
          <Col span={24}>
            <Card title="Plugins" bordered>
              <Table
                columns={[
                  { title: 'Name', dataIndex: 'plugin_name', key: 'plugin_name' },
                  { title: 'Version', dataIndex: 'plugin_version', key: 'plugin_version' },
                  { title: 'Update Status', dataIndex: 'update_status', key: 'update_status' },
                  { title: 'Update Version', dataIndex: 'update_version', key: 'update_version' }
                ]}
                dataSource={plugins}
                rowKey="id"
                pagination={{ pageSize: 10 }}
              />
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default ProjectDetail;
