import React from 'react';
import { Menu, Divider, Button } from 'antd';
import { Link } from 'react-router-dom';
import { getAuth, signOut } from "firebase/auth";
import { useDispatch, useSelector } from 'react-redux';
import { setUserLogOutState } from "../../store/slices/userReducer.js";
import { HomeOutlined, SearchOutlined, LogoutOutlined, TeamOutlined } from '@ant-design/icons';
import Logo from '../../images/logo.js';
import app from '../../auth/firebase.js';

const Sidebar = () => {

  const userType = useSelector((state) => state.user.userType);
  const dispatch = useDispatch();
  const auth = getAuth(app);

  const handleLogout = () => {
    console.log("Attempting to log out..."); // Debug log
    signOut(auth).then(() => {
      console.log("Logout successful"); // Debug log
      dispatch(setUserLogOutState());
    }).catch((error) => {
      console.error('Logout Error:', error);
    });
  };

  const logout = {
    key: 'logout',
    icon: <LogoutOutlined />,
    label: 'Logout',
    onClick: handleLogout, // Attach the logout function directly to the menu item
  };
 
  // Define your default items and specific items arrays here
  const itemsRoot = [
    {
      key: 'logo',
      label: (
        <div className="logo-wrapper">
          <Logo />
        </div>
      ),
      type: 'group', // This ensures the logo is treated differently from other menu items
    },
    {
      key: 'dashboard',
      icon: <HomeOutlined />,
      label: <Link to="/">Dashboard</Link>,
    },
  ];

  const itemsBasic = [...itemsRoot, logout];
  const itemsStrategist = [...itemsRoot, 
    {
      key: 'search',
      icon: <SearchOutlined />,
      label: <Link to="/search">Search</Link>,
    }, logout];
  const itemsExec = [...itemsRoot, logout];
  const itemsAdmin = [...itemsRoot, 
    {
      key: 'search',
      icon: <SearchOutlined />,
      label: <Link to="/search">Search</Link>,
    }, 
    {
      key: 'user_admin',
      icon: <TeamOutlined />,
      label: <Link to="/admin/users">Users Admin</Link>,
    }, logout];

  let items;
  switch (userType) {
    case 'strategist':
      items = itemsStrategist;
      break;
    case 'exec':
      items = itemsExec;
      break;
    case 'admin':
      items = itemsAdmin;
      break;
    default:
      items = itemsBasic;
  }

  return (
    <Menu
      mode="inline"
      style={{ height: '100%', borderRight: 0 }}
      items={items}
    />
  );
};

export default Sidebar;
